import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ProjectContent } from '../mlva-types';

interface MobileProjectSlideshowProps {
  content: ProjectContent;
}

const MobileProjectSlideshow = ({ content }: MobileProjectSlideshowProps) => {
  const { projectTitle, projectPhotos } = content;

  return (
    <>
      {projectPhotos?.map((photo, index: number) => {
        const image = getImage(photo.photo);
        if (image === undefined) return;
        return (
          <div key={index} className="py-2">
            <GatsbyImage
              image={image}
              alt={projectTitle + ' | photograph ' + (index + 1)}
              className="project-slide"
              objectFit="contain"
            />
          </div>
        );
      })}
    </>
  );
};

export default MobileProjectSlideshow;
