import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from '../hooks/useTranslation';
import { Language, ProjectContent } from '../mlva-types';
import { ProjectDetailBlock } from './project-detail-block';
import useScrollDetected from '../hooks/useDetectScroll';

interface ProjectDetailsProps {
  content: ProjectContent;
  language: Language;
}

export const ProjectDetails = ({ content, language }: ProjectDetailsProps) => {
  const scrollDetected = useScrollDetected(150);

  return (
    <div className="columns project-details is-multiline">
      <div className="column is-narrow project-title py-0 my-0">
        <ScrollLink
          activeClass="active"
          to={scrollDetected.isTop ? 'project-title' : 'project-main'}
          smooth={true}
          duration={500}
        >
          <h1 className="avoid-slideshow">
            {content.projectTitle}
            <span className="toggle-project-details">
              {scrollDetected.isTop ? (
                <FontAwesomeIcon
                  icon={faPlus}
                  size="2xs"
                  id="avoid-slideshow"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faMinus}
                  size="2xs"
                  id="avoid-slideshow"
                />
              )}
            </span>
          </h1>
        </ScrollLink>
      </div>
      {content.client && (
        <ProjectDetailBlock
          label={useTranslation('Client', 'Cliente', language)}
          value={content.client}
        />
      )}
      {content.buildingArea && (
        <ProjectDetailBlock
          label={useTranslation(
            'Building area',
            'Área de construção',
            language
          )}
          value={content.buildingArea}
        />
      )}
      {content.projectDate && (
        <ProjectDetailBlock
          label={useTranslation('Project date', 'Data do projeto', language)}
          value={content.projectDate}
        />
      )}
      {content.constructionDate && (
        <ProjectDetailBlock
          label={useTranslation(
            'Construction date',
            'Data de construção',
            language
          )}
          value={content.constructionDate}
        />
      )}
      {content.status && (
        <ProjectDetailBlock
          label={useTranslation('Phase', 'Fase', language)}
          value={content.status}
        />
      )}
      {content.designBy && (
        <>
          <ProjectDetailBlock
            label={useTranslation('Team design', 'Equipa de design', language)}
          />
          <span className="column is-6">
            {content.designBy?.map((entry: { teamMember: string }) => (
              <div className="span" key={entry.teamMember}>
                {entry.teamMember}
              </div>
            ))}
          </span>
        </>
      )}
      {content.photosBy?.length > 0 && (
        <>
          <ProjectDetailBlock
            label={useTranslation('Photography by', 'Fotografia por', language)}
          />
          <span className="column is-6">
            {content.photosBy?.map((entry: { photographer: string }) => (
              <div className="span" key={entry.photographer}>
                {entry.photographer}
              </div>
            ))}
          </span>
        </>
      )}
      {content.publishers?.map(
        (publisher: { name: string; linkTo: string | undefined }) => {
          return (
            <div
              className="column is-12 project-publishers"
              key={publisher.name}
            >
              {publisher.linkTo ? (
                <a
                  href={publisher.linkTo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="project-publisher">{publisher.name}</span>
                </a>
              ) : (
                <span>{publisher.name}</span>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};
