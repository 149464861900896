import React from 'react';
import { Link, navigate } from 'gatsby';
import {
  getLanguageFromLocation,
  useTranslation,
} from '../hooks/useTranslation';
import MobileProjectSlideshow from '../components/mobile-project-slideshow';
import {
  Language,
  LinkLocation,
  ProjectContent,
  Translation,
} from '../mlva-types';
import useScrollDetected, { DirectionEnum } from '../hooks/useDetectScroll';

interface ProjectProps {
  data: {
    project: {
      frontmatter: ProjectContent;
    };
    logo: {
      frontmatter: {
        logo: {
          publicURL: string;
        };
      };
    };
  };
  location: LinkLocation;
}

const MobileProject = ({ data, location }: ProjectProps) => {
  const scrollDetected = useScrollDetected(50, 10);

  const language: Language = getLanguageFromLocation(location);

  const content: ProjectContent = data.project.frontmatter;

  return (
    <>
      <nav
        className={`navbar is-transparent is-fixed-top is-unselectable ${
          scrollDetected.direction == DirectionEnum.Down
            ? 'navbar-hide'
            : 'navbar-display'
        }`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link
            to="/"
            className="navbar-item px-4 py-0"
            state={{ language: language }}
          >
            <img
              src={data.logo.frontmatter.logo?.publicURL}
              alt="navigate to homepage"
              id="navbar-logo"
            />
          </Link>
          <a
            role="button"
            className="navbar-burger mr-2 is-active"
            aria-label="close project"
            aria-expanded="false"
            onClick={() => {
              navigate('/work', { state: { language: language } });
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
      </nav>
      <section>
        <div className="after-navbar">
          <MobileProjectSlideshow content={content} />
        </div>
        <h1 className="my-5 ml-5">{content.projectTitle}</h1>
        <div className="columns is-mobile is-multiline px-5 py-5">
          {content.client && (
            <>
              <h3 className="column is-6">
                {useTranslation('Client', 'Cliente', language)}
              </h3>
              <span className="column is-6">{content.client}</span>
            </>
          )}
          {content.buildingArea && (
            <>
              <h3 className="column is-6">
                {useTranslation(
                  'Building area',
                  'Área de construção',
                  language
                )}
              </h3>
              <span className="column is-6">{content.buildingArea}</span>
            </>
          )}
          {content.projectDate && (
            <>
              <h3 className="column is-6">
                {useTranslation('Project date', 'Data do projeto', language)}
              </h3>
              <span className="column is-6">{content.projectDate}</span>
            </>
          )}
          {content.constructionDate && (
            <>
              <h3 className="column is-6">
                {useTranslation(
                  'Construction date',
                  'Data de construção',
                  language
                )}
              </h3>
              <span className="column is-6">{content.constructionDate}</span>
            </>
          )}
          {content.status && (
            <>
              <h3 className="column is-6">
                {useTranslation('Phase', 'Fase', language)}
              </h3>
              <span className="column is-6">{content.status}</span>
            </>
          )}
          {content.designBy && (
            <>
              <h3 className="column is-6">
                {useTranslation('Team design', 'Equipa de design', language)}
              </h3>
              <span className="column is-6">
                {content.designBy?.map((entry: { teamMember: string }) => (
                  <div className="span">{entry.teamMember}</div>
                ))}
              </span>
            </>
          )}
          {content.photosBy?.length > 0 && (
            <>
              <h3 className="column is-6">
                {useTranslation('Photography by', 'Fotografia por', language)}
              </h3>
              <span className="column is-6">
                {content.photosBy?.map((entry: { photographer: string }) => (
                  <div className="span">{entry.photographer}</div>
                ))}
              </span>
            </>
          )}
          {content.publishers?.map(
            (publisher: { name: string; linkTo: string | undefined }) => {
              return (
                <div className="column is-12 mt-5">
                  {publisher.linkTo ? (
                    <a
                      href={publisher.linkTo}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{publisher.name}</span>
                    </a>
                  ) : (
                    <span>{publisher.name}</span>
                  )}
                </div>
              );
            }
          )}
          <div className="column is-12 mt-5">
            {content.description?.length > 0 &&
              content.description?.map((paragraph: Translation) => {
                return (
                  <div className="block has-text-justified">
                    {useTranslation(paragraph.en, paragraph.pt, language)}
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileProject;
