import React from 'react';

interface ProjectDetailBlockProps {
  label: string;
  value?: string;
}

export const ProjectDetailBlock = ({
  label,
  value,
}: ProjectDetailBlockProps) => {
  return (
    <>
      <h3 className="column is-6">{label}</h3>
      {value && <span className="column is-6">{value}</span>}
    </>
  );
};
