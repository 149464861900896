import React from 'react';
import './mystyles.scss';
import { graphql, navigate } from 'gatsby';
import ProjectSlideshow from '../components/project-slideshow';
import {
  getLanguageFromLocation,
  useTranslation,
} from '../hooks/useTranslation';
import MobileProject from '../components/mobile-project';
import { LinkLocation, ProjectContent, Translation } from '../mlva-types';
import { DesktopProjectNavbar } from '../components/project-navbar';
import { ProjectDetails } from '../components/project-details';
import { SEO } from '../components/seo';

interface ProjectProps {
  data: {
    project: {
      frontmatter: ProjectContent;
    };
    logo: {
      frontmatter: {
        logo: {
          publicURL: string;
        };
      };
    };
  };
  location: LinkLocation;
}

const Project = ({ data, location }: ProjectProps) => {
  const language = getLanguageFromLocation(location);

  const content = data.project.frontmatter;

  return (
    <>
      <div className="is-hidden-desktop">
        <MobileProject data={data} location={location} />
      </div>
      <DesktopProjectNavbar
        logo={data.logo.frontmatter.logo?.publicURL}
        language={language}
        linkTo="/work"
      />
      <section className="columns is-multiline project is-hidden-touch">
        <div className="column is-1"></div>
        <div className="column" id="project-main">
          <ProjectSlideshow content={content} />
        </div>
        <div className="column is-1">
          <a
            role="button"
            className="project-close navbar-burger mr-2 is-active"
            aria-label="close project"
            aria-expanded="false"
            onClick={() => {
              navigate('/work', { state: { language: language } });
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className="column is-12"></div>
        <div className="column is-3">
          <ProjectDetails content={content} language={language} />
        </div>
        <div className="column is-6">
          {content.description?.length > 0 &&
            content.description?.map((paragraph: Translation) => {
              return (
                <div className="block has-text-justified">
                  {useTranslation(paragraph.en, paragraph.pt, language)}
                </div>
              );
            })}
        </div>
        <div className="column is-3" />
        <div id="project-footer" />
      </section>
    </>
  );
};

export default Project;

export const Head = ({ data }: any) => (
  <SEO title={`MLVA | ${data.project.frontmatter.projectTitle}`} />
);

export const ProjectQuery = graphql`
  query ProjectPage($id: String) {
    project: markdownRemark(id: { eq: $id }) {
      frontmatter {
        projectTitle
        coverPhoto {
          absolutePath
        }
        client
        buildingArea
        projectDate
        constructionDate
        status
        designBy {
          teamMember
        }
        photosBy {
          photographer
        }
        publishers {
          name
          linkTo
        }
        description {
          en
          pt
        }
        projectPhotos {
          photo {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
    logo: markdownRemark(
      frontmatter: { templateKey: { eq: "homepage-section" } }
    ) {
      frontmatter {
        logo {
          publicURL
        }
      }
    }
  }
`;
